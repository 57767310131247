const local = 0;

const cmsUrl = local === 1 ? "http://localhost:8080" : "https://atelierstadsrevisie.nl/cms";
const cmsImageUrl =  "https://atelierstadsrevisie.nl/cms/storage/uploads";
const baseUrl = "https://atelierstadsrevisie.nl/cms";
const token = "a91b994337de2b684755d840af6562";

const config = {
    cookieName: "cookie.atelierstadsrevisie.nl",
    cookieDebug: false,
    siteTitle: "ASTA :: ",
    siteName: "Atelier Stadsrevisie",
    siteUrl: baseUrl,
    protocol: 'https',
    cmsUrl: cmsUrl,
    cmsImageUrl: cmsImageUrl,
    apiKey: '?token=' + token,
    homepageAPI: "/api/singletons/get/Homepage",
    pagesAPI: "/api/collections/get/Page",
    newsAPI: "/api/collections/get/News",
    structureAPI: "/api/collections/get/TopMenu",
    blockRowAPI: "/api/collections/get/pageBlocks",
    formsAPI: "/api/forms/submit/informationRequest",
    formsExport: "/api/forms/export/informationRequest",
    numNews: 2,
    fetchKey: "9f36fce771d19d5c612ae8f8e9bc3760e7b71519",
}


export default config;
