import React, { Component } from 'react';
import Logo from "../resources/images/asta-logo-v2.svg";
import config from "../config/config";
import MobileMenu from "./MobileMenu";


class MobileHead extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeItem: this.props.activeItem,
            showArrow: this.props.showArrow ? true : false,
            className: this.props.passingClass,
            bg: this.props.bg ? this.props.bg : false,
            data: this.props.data,
            windowSize: {
                w: window.innerWidth,
                h: window.innerHeight,
            }
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {

        let showMenu = window.innerWidth <= 960 ? false : true;
        this.setState({
            showMenu: showMenu,
            windowSize: {
                w: window.innerWidth,
                h: window.innerHeight,
            }
        });
    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    renderArrow() {
        if(this.state.showArrow) {
            return(
                <div className="downbox">
                    <i className="fa fa-chevron-down"></i>
                </div>
            )
        }
    }

    render() {

        let w = this.state.windowSize.w;
        let h = this.state.windowSize.h / 2;

        let style = this.props.bg ? { width: w, height: h, background: this.props.bg, backgroundSize: "cover", backgroundPosition: "center" } : { width: w, height: h };

        return(
            <React.Fragment>
                <a href="/" style={{ width: w, height: h }} className="mobile-home-grid-top">
                    <img src={ Logo } alt={ config.siteName }/>
                </a>
                <div style={style} className={ this.state.className}>
                    <MobileMenu data={this.state.data} activeItem={ this.state.activeItem } />
                    { this.renderArrow() }
                </div>
            </React.Fragment>
        )
    }

}

export default MobileHead;
