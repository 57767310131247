import React, { Component } from 'react';
//import { BrowserRouter, Route } from 'react-router-dom';
//import Grid from '@material-ui/core/Grid';


class MobileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeItem: this.props.activeItem,
            items: this.props.data.pages.entries,

        }
    }

    componentDidMount() {

    }

    render() {
        return(
            <React.Fragment>

                <div className="mobileMenu">
                    <ul>
                        {
                            this.state.items.map( (entry, index) => {

                                let pth =  "/" + entry.context + "/" + entry.slug;
                                let title = entry.pageTitle;
                                let active = this.state.activeItem === entry.slug ? true : false;

                                return(
                                    <li key={ index.toString() } >
                                        <a href={pth}  className={ active ? "active" : this.state.activeItem}>{title}</a>
                                    </li>
                                )
                            })

                        }
                    </ul>
                </div>

            </React.Fragment>
        )
    }

}

export default MobileMenu;
