import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
//import GridLoader from 'react-spinners/GridLoader';

import Grid from '@material-ui/core/Grid';
import './App/resources/styles/App.css';

import API from './App/lib/API';
import Home from './App/views/Home';
import ContentView from './App/views/ContentView';
import Inschrijving from './App/views/Inschrijving';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      isError: false,
      data: [],
    }
  }

  componentDidMount() {

    API.fetchData()
        .then( result => {

          this.setState({
            isLoaded: true,
            isError: false,
            data: result,
          })

        })
        .catch(err => {
        })

  }


  /* TODO: configure routes */
  render() {

    if(this.state.isLoaded) {

      return (
          <BrowserRouter>

            <Grid container spacing={0}>
              <Route path="/" exact
                     render={ (props) => <Home { ...props }
                                               data={this.state.data }
                     /> }>

              </Route>
              <Route path="/asta/:page" exact
                     render={ (props) => <ContentView { ...props }
                                                      data={this.state.data }
                     /> }>

              </Route>

                <Route path="/inschrijvingen" exact
                       render={ (props) => <Inschrijving { ...props } /> }>

                </Route>

            </Grid>

          </BrowserRouter>
      );

    } else {
      return(
          <div></div>
          // <div className='sweet-loading'>
          //   <GridLoader
          //       sizeUnit={"px"}
          //       width={ 3 }
          //       height={ 20 }
          //       margin="4px"
          //       color={'#FFF'}
          //       loading={!this.state.isLoaded}
          //   />
          // </div>
      );
    }
  }
}

export default App;
