import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import GridLoader from 'react-spinners/GridLoader';
import DateFnsUtils from '@date-io/date-fns';

import API from "../lib/API";


import {
    MuiPickersUtilsProvider,
//    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';


import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#CD1719"
        },
        secondary: {
            main: '#CCCCCC',
        },
    },
});

class ContactForm extends Component {

    constructor(props) {
        super(props);
        this.state = {
            honeypot: "",
            applicationState: 1,
            full_name: "",
            isFullNameError: false,
            address: "",
            isAddressError: false,
            birthDate: null,
            isBirthDateError: false,
            availFrom: null,
            availUntil: null,
            postcode: "",
            isPostCodeError: false,
            city: "",
            isCityError: false,
            phone: "",
            email: "",
            isEmailError: false,
            occupation: "",
            wanttodo: "",
            checkedExperience: false,
        }
    }


    changeItem(item, evt) {

        const target = evt.target;
        const value = target.type === 'checkbox' ?  target.checked : target.value;

        this.setState({
            [item]: value
        });

    }

    checkItem(item, min, format = "") {
        if(item === "" || item.length < min) {
            return(false);
        }
        return(true);
    }

    checkRequired() {

        let isValid = true;
        let isFullNameError = false;
        let isAddressError = false;
        let isPostCodeError = false;
        let isCityError = false;
        let isEmailError = false;
        let isBirthDateError = false;

        if(!this.checkItem(this.state.full_name ? this.state.full_name : "" , 3)) {
            isFullNameError = true;
            isValid = false;
        }

        if(!this.checkItem(this.state.address ? this.state.address : "", 3)) {
            isAddressError = true;
            isValid = false;
        }

        if(!this.checkItem(this.state.postcode ? this.state.postcode : "", 6)) {
            isPostCodeError = true;
            isValid = false;
        }

        if(!this.checkItem(this.state.city, 3)) {
            isCityError = true;
            isValid = false;
        }

        if(!this.checkItem(this.state.email ? this.state.email : "", 3)) {
            isEmailError = true;
            isValid = false;
        }

        if(!API.validateEmail(this.state.email ? this.state.email : "")) {
            isEmailError = true;
            isValid = false;
        }

        // if(!this.checkItem(this.state.birthDate ? this.state.birthDate : "", 3)) {
        //     isBirthDateError = true;
        //     isValid = false;
        // }


        this.setState({
            isFullNameError: isFullNameError,
            isAddressError: isAddressError,
            isPostCodeError: isPostCodeError,
            isCityError:isCityError,
            isEmailError:isEmailError,
         //   isBirthDateError: isBirthDateError
        });

        return(isValid)
    }

    submitForm() {

        if(this.state.honeypot === "") {

            if(this.checkRequired()) {

                this.setState({
                    applicationState: 2
                });

                API.sendForm(this.state)
                    .then(result => {
                        this.setState({
                            applicationState: 3
                        });
                    })
                    .catch(err => {
                        this.setState({
                            applicationState: 4
                        });
                    })
            } else {
                alert("Het formulier heeft fouten.");
            }

        } else {

            this.setState({
                applicationState: 4
            });
        }


    }

    renderThankYou() {
        return(
            <React.Fragment>
                <div className='sweet-sending' style={{ flexDirection: "column", padding: 20 }}>
                    <div style={{ padding: 10 }}>
                        <i className="fa fa-thumbs-up" style={{ color: "#CD1719", fontSize: 44 }}></i>
                    </div>
                    <div>
                        <h4>We laten zo snel mogelijk iets van ons horen!</h4>
                        Dankjewel voor je belangstelling
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderError() {
        return(
            <React.Fragment>
                <div className='sweet-sending' style={{ flexDirection: "column", padding: 20 }}>>
                    <div style={{ padding: 10 }}>
                        <i className="fa fa-thumbs-down" style={{ color: "#CD1719", fontSize: 44 }}></i>
                    </div>
                    <div style={{ padding: 10 }}>
                    Er ging iets mis bij het versturen van je aanmelding.<br/>
                    probeer het later nog eens.
                        </div>
                </div>
            </React.Fragment>
        )
    }

    renderSending() {
        return(
            <React.Fragment>
                 <div className='sweet-sending'>
                   <GridLoader
                       sizeUnit={"px"}
                       width={ 3 }
                       height={ 20 }
                       margin="4px"
                       color={'#cd1719'}
                       loading={!this.state.isLoaded}
                   />
                 </div>
            </React.Fragment>
        )
    }

    renderForm() {
        return(
            <ThemeProvider theme={theme}>
                <h2>CONTACT</h2>
                <div class="textParagraph">
                <p>Vul het contact formulier in.</p>
                </div>
                <form noValidate autoComplete="off" method="POST" className="formContainer">

                    <Grid container spacing={2} className="container">
                <Grid item xs={12} md={12}>
                <FormControl fullWidth style={{ margin: 10 }} theme={theme}>
                    <InputLabel htmlFor="standard-adornment-amount">Je naam *</InputLabel>
                    <Input
                        id="full_name"
                        error={this.state.isFullNameError}
                        value={this.state.full_name}
                        onChange={ (evt) => this.changeItem("full_name", evt)}
                        required
                        color="primary"
                    />

                    <Input
                        id="email_extra"
                        value={this.state.honeypot}
                        onChange={ (evt) => this.changeItem("honeypot", evt)}
                        type="hidden"
                        name="email_extra"
                    />
                </FormControl>
                </Grid>

                <Grid item xs={12} md={12}>
                <FormControl fullWidth style={{ margin: 10 }}>
                    <InputLabel htmlFor="standard-adornment-amount">Je adres *</InputLabel>
                    <Input
                        error={ this.state.isAddressError }
                        id="address"
                        value={this.state.address}
                        onChange={ (evt) => this.changeItem("address", evt)}
                        required
                        color="primary"
                    />
                </FormControl>
                </Grid>
                    </Grid>


                <Grid container spacing={2} className="container">
                    <Grid item xs={12} sm={12} md={4}>
                    <FormControl fullWidth style={{ margin: 10 }}>
                        <InputLabel htmlFor="standard-adornment-amount">Postcode *</InputLabel>
                        <Input
                            id="postcode"
                            error={this.state.isPostCodeError}
                            value={this.state.postcode}
                            onChange={ (evt) => this.changeItem("postcode", evt)}
                            required
                            color="primary"
                        />
                    </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={12} md={8}>
                    <FormControl fullWidth style={{ margin: 10 }}>
                        <InputLabel htmlFor="standard-adornment-amount">Woonplaats *</InputLabel>
                        <Input
                            id="city"
                            error={this.state.isCityError}
                            value={this.state.city}
                            onChange={ (evt) => this.changeItem("city", evt)}
                            required
                            color="primary"
                        />
                    </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2} className="container">

                   <Grid item xs={12} sm={12} lg={4} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar
                                                variant="inline"
                                                format="dd-MM-yyyy"
                                                style={{ margin :10}}
                                                id="birthDate"
                                                label="Geboorte datum"
                                                value={this.state.birthDate}
                                                onChange={ (date) => (this.setState({ birtDate: date })) }
                                                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                            />
                        </MuiPickersUtilsProvider>

                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <FormControl fullWidth style={{ margin: 10 }}>
                            <InputLabel htmlFor="standard-adornment-amount">Telefoon</InputLabel>
                            <Input
                                id="phone"
                                value={this.state.phone}
                                onChange={ (evt) => this.changeItem("phone", evt)}
                                color="primary"
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={ 12 } lg={4}>
                        <FormControl fullWidth style={{ margin: 10 }}>
                            <InputLabel htmlFor="email">Email *</InputLabel>
                            <Input
                                id="email"
                                error={ this.state.isEmailError }
                                value={this.state.email}
                                onChange={ (evt) => this.changeItem("email", evt)}
                                required
                                color="primary"
                            />
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2} className="container">
                <Grid item xs={12} md={8}>
                    <FormControl fullWidth style={{ margin: 10 }}>
                        <InputLabel htmlFor="standard-adornment-amount">Heb/Had je een beroep, zo ja, welk?</InputLabel>
                        <Input
                            id="beroep"
                            value={this.state.occupation}
                            onChange={ (evt) => this.changeItem("occupation", evt)}
                            color="primary"
                        />
                    </FormControl>
                </Grid>

                <Grid item xs={12} md={4} style={{ paddingTop: 20}}>
                    <InputLabel htmlFor="standard-adornment-amount">Als stageplek?</InputLabel>
                    <Switch
                        checked={this.state.checkedTraineeship}
                        onChange={(evt) => this.changeItem("checkedTraineeship", evt)}
                        name="checkedTraineeship"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                    />
                </Grid>
                </Grid>

                <Grid container spacing={2} className="container">

                <Grid item xs={12} md={8}>
                    <FormControl fullWidth style={{ margin: 10 }}>
                        <InputLabel htmlFor="standard-adornment-amount">Wat zou je willen doen?</InputLabel>
                        <Input
                            id="wanttodo"
                            value={this.state.wanttodo}
                            onChange={ (evt) => this.changeItem("wanttodo", evt)}
                            color="primary"
                        />
                    </FormControl>


                </Grid>
                <Grid item xs={12} md={4} style={{ paddingTop: 20}}>
                    <InputLabel htmlFor="standard-adornment-amount">Heb je ervaring hierin?</InputLabel>
                    <Switch
                        checked={this.state.checkedExperience}
                        onChange={(evt) => this.changeItem("checkedExperience", evt)}
                        name="checkedExperience"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        color="primary"
                    />
                </Grid>

                </Grid>


                    <Grid container spacing={2} className="container">

                <InputLabel htmlFor="standard-adornment-amount" style={{ marginTop:40, padding: 10}}>Wanneer ben je beschikbaar?</InputLabel>
                <Grid container spacing={2} style={{ padding: 10}} className="container">

                    <Grid item xs={12} md={4} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar
                                                variant="inline"
                                                format="dd-MM-yyyy"
                                                margin="normal"
                                                id="from"
                                                label="Van"
                                                value={this.state.availFrom}
                                                onChange={ (date) => (this.setState({ availFrom: date })) }
                                                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} md={4} >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker disableToolbar
                                                variant="inline"
                                                format="dd-MM-yyyy"
                                                margin="normal"
                                                id="until"
                                                label="Tot"
                                                value={this.state.availUntil}
                                                onChange={ (date) => (this.setState({ availUntil: date })) }
                                                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                            />
                        </MuiPickersUtilsProvider>
                    </Grid>

                </Grid>
                    </Grid>
                    <Grid container spacing={2} className="container">
                <InputLabel htmlFor="standard-adornment-amount" style={{ marginTop:40, padding: 10}}>Welke dagen ben je beschikbaar?</InputLabel>

                <Grid item xs={12} md={12} >
                    <table style={{ width: "100%"}}>
                        <thead>
                        <tr>
                            <th></th>
                            <th>Ma.</th>
                            <th>Di.</th>
                            <th>Wo.</th>
                            <th>Do.</th>
                            <th>Vrij.</th>
                            <th>Za.</th>
                            <th>Zo.</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td><strong>Ochtend</strong></td>
                            <td><Checkbox
                                checked={this.state.monMorning}
                                color="primary"
                                onChange={ (evt) => this.changeItem("monMorning", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.tueMorning}
                                color="primary"
                                onChange={ (evt) => this.changeItem("tueMorning", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.wedMorning}
                                color="primary"
                                onChange={ (evt) => this.changeItem("wedMorning", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.thurMorning}
                                color="primary"
                                onChange={ (evt) => this.changeItem("thurMorning", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.friMorning}
                                color="primary"
                                onChange={ (evt) => this.changeItem("friMorning", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.satMorning}
                                color="primary"
                                onChange={ (evt) => this.changeItem("satMorning", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.sunMorning}
                                color="primary"
                                onChange={ (evt) => this.changeItem("sunMorning", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                        </tr>

                        <tr>
                            <td><strong>Middag</strong></td>
                            <td><Checkbox
                                checked={this.state.monAfternoon}
                                color="primary"
                                onChange={ (evt) => this.changeItem("monAfternoon", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.tueAfternoon}
                                color="primary"
                                onChange={ (evt) => this.changeItem("tueAfternoon", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.wedAfternoon}
                                color="primary"
                                onChange={ (evt) => this.changeItem("wedAfternoon", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.thurAfternoon}
                                color="primary"
                                onChange={ (evt) => this.changeItem("thurAfternoon", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.friAfternoon}
                                color="primary"
                                onChange={ (evt) => this.changeItem("friAfternoon", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.satAfternoon}
                                color="primary"
                                onChange={ (evt) => this.changeItem("satAfternoon", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.sunAfternoon}
                                color="primary"
                                onChange={ (evt) => this.changeItem("sunAfternoon", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                        </tr>

                        <tr>
                            <td><strong>Avond</strong></td>
                            <td><Checkbox
                                checked={this.state.monEvening}
                                color="primary"
                                onChange={ (evt) => this.changeItem("monEvening", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.tueEvening}
                                color="primary"
                                onChange={ (evt) => this.changeItem("tueEvening", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.wedEvening}
                                color="primary"
                                onChange={ (evt) => this.changeItem("wedEvening", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.thurEvening}
                                color="primary"
                                onChange={ (evt) => this.changeItem("thurEvening", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.friEvening}
                                color="primary"
                                onChange={ (evt) => this.changeItem("friEvening", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.satEvening}
                                color="primary"
                                onChange={ (evt) => this.changeItem("satEvening", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                            <td><Checkbox
                                checked={this.state.sunEvening}
                                color="primary"
                                onChange={ (evt) => this.changeItem("sunEvening", evt) }
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            /></td>
                        </tr>



                        </tbody>
                    </table>

                </Grid>

                <InputLabel htmlFor="standard-adornment-amount" style={{ marginTop:40, padding: 10}}></InputLabel>

                <Grid item xs={12} sm={ 12} md={12} >
                    <TextField
                        id="outlined-multiline-static"
                        label="Vragen/Opmerkingen?"
                        multiline
                        rows={4}
                        defaultValue=""
                        variant="outlined"
                        style={{ width :"95%", marginBottom: 40}}
                    />
                </Grid>
                    </Grid>

                <div style={{ margin: "auto", textAlign: "center"}}>
                    <Button variant="contained" color="primary" href="#contained-buttons" onClick={ () => this.submitForm() }>
                        Verstuur
                    </Button>
                </div>

            </form>
            </ThemeProvider>
        )
    }

    renderContent() {

        switch(this.state.applicationState) {


            case 2: {
                return(
                    <React.Fragment>
                        { this.renderSending() }
                    </React.Fragment>
                )
            }


            case 3: {
                return(
                    <React.Fragment>
                        { this.renderThankYou() }
                    </React.Fragment>
                )
            }

            case 4: {
                return(
                    <React.Fragment>
                        { this.renderError() }
                    </React.Fragment>
                )
            }

            default: {
                return(
                    <React.Fragment>
                        { this.renderForm() }
                    </React.Fragment>
                )

            }
        }


    }


    render() {


        return(
            <React.Fragment>
                { this.renderContent() }
            </React.Fragment>
        )
    }


}


export default ContactForm;
