import React, { Component } from 'react';
import BeforeAfterSlider from 'react-before-after-slider'
import API from "../lib/API";

export default class ImageComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            imageType: this.props.imageType,
            windowSize: {
                w: window.innerWidth,
                h: window.innerHeight,
                scale: window.innerWidth / 2.8
            }
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }


    updateWindowDimensions() {

        let scale = window.innerWidth <= 600 ? window.innerWidth - 60 :  window.innerWidth / 2.8;

        this.setState({
                windowSize: {
                    w: window.innerWidth,
                    h: window.innerHeight,
                    scale: scale
                }
                });
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    renderImage() {
        if(this.state.imageType === "slider") {

            let before = API.createImage(this.state.data.preImage.path);
            let after = API.createImage(this.state.data.postImage.path);

            return(

                <BeforeAfterSlider
                    before={after}
                    after={before}
                    width={this.state.windowSize.scale}
                    height={this.state.windowSize.scale * 1.2}
                />

            )

        }

        let img = API.createImage(this.state.data.blockImage.path)
        return(
            <img src={img} alt="" style={{ width: this.state.windowSize.scale }}/>
        )
    }

    renderByLine() {
        if(this.state.data.prepostByLine) {
            return(<div>
                <div className="by-line">{this.state.data.prepostByLine}</div>
            </div>);
        }
    }

    renderAdditionalText() {
        if(this.state.data.prepostAdditionalText) {
            return(<div className="textParagraph"
                        dangerouslySetInnerHTML={{ __html: "<p>" + this.state.data.prepostAdditionalText + "</p>"}} ></div>)
        }
    }


    render() {
        return(

        <div className="project-slider">
                { this.renderByLine() }
                { this.renderImage() }
                { this.renderAdditionalText() }
        </div>

        );
    }


}
