import React, { Component } from 'react';

export default class TextComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data
        }
    }

    componentDidMount() {
        console.warn(this.state.data)
    }

    renderTitle(title) {
        if(title !== "") {
            return(<h2>{ title }</h2>)
        }
    }

    renderSubTitle(title) {
        if(title !== "") {
            return(<h3>{ title }</h3>)
        }
    }

    renderTextParagraph(text) {
        if(text !== "") {
            return(<div className="textParagraph"
                        dangerouslySetInnerHTML={{ __html: text }}></div>)
        }
    }

    render() {
        return(
            <React.Fragment>
                { this.renderTitle(this.state.data.itemTitle ) }
                { this.renderSubTitle(this.state.data.itemSubTitle ) }
                { this.renderTextParagraph(this.state.data.itemContent ) }
            </React.Fragment>
        );
    }


}
