import React, { Component } from 'react';
//import { BrowserRouter, Route } from 'react-router-dom';
import config from "../config/config";
import Grid from '@material-ui/core/Grid';
import Logo from '../resources/images/asta-logo-v2.svg';
//import backdrop from '../resources/images/maan-kwartier.png';
import MainMenu from "../components/MainMenu";
import { Helmet } from 'react-helmet';
import MobileMenu from "../components/MobileMenu";
import MobileHead from "../components/MobileHead";


class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: this.props.data,
            backdrop: '../images/maan-kwartier.png',
            isLoaded: false,
            windowSize: {
                w: window.innerWidth,
                h: window.innerHeight,
                scale: 1
            }
        }


        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    updateWindowDimensions() {

        let showMenu = window.innerWidth <= 960 ? false : true;
        this.setState({
            showMenu: showMenu,
            windowSize: {
                w: window.innerWidth,
                h: window.innerHeight,
            }
        });
    }


    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }


    renderContent() {

        if(this.state.windowSize.w < 960 ) {
            return(
              <MobileHead passingClass="home-mobile-image-block"
                          data={this.state.data}
                          showArrow={false}/>
            )
        }

        return(
            <Grid container className="homeGrid" spacing={0}>
                <MainMenu data={this.state.data }/>

                <Grid item xs={6} md={6} className="pageLeft">
                    <img src={ Logo } alt={ config.siteName }/>
                </Grid>

                <Grid item xs={6} md={6} className="pageRight">
                    <div className="content">
                    </div>
                </Grid>

            </Grid>
        )
    }


    render() {
        return(
            <React.Fragment>
                <Helmet>
                    <title>{ config.siteTitle} Homepage</title>
                    <meta name="description" content="" />
                </Helmet>

                { this.renderContent() }

            </React.Fragment>
        )
    }



}


export default Home;
