import React, { Component } from 'react';
//import { BrowserRouter, Route } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import MainMenu from "../components/MainMenu";
//import Logo from "../resources/images/asta-logo-v2.svg";
import API from "../lib/API";
import TextComponent from "../components/TextComponent";
import ImageComponent from "../components/ImageComponent";
import ContactForm from '../components/ContactForm';

import {Helmet} from "react-helmet";
import config from "../config/config";
import MobileHead from "../components/MobileHead";

class ContentView extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            data: this.props.data,
            context: this.props.match.params.context,
            slug: this.props.match.params.page,
            currentPage: {},
            showMenu: true,
            windowSize: {
                w: window.innerWidth,
                h: window.innerHeight,
                scale: 1
            }
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);


    }

    updateWindowDimensions() {

        let showMenu = window.innerWidth <= 960 ? false : true;
        this.setState({
            showMenu: showMenu,
            windowSize: {
                w: window.innerWidth,
                h: window.innerHeight,
            }
        });
    }

    componentDidMount() {

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);


        let pgData = {};
        this.state.data.pages.entries.forEach( item => {
            if(item.slug === this.state.slug ) {
                pgData = item;
            }
        });

        this.setState({ currentPage: pgData, isLoaded: true });
    }


    renderContent() {


        return(
            <React.Fragment>
                {
                    this.state.currentPage.content.map( (item, index) => {

                        if( item.field.name === "blockImageItem") {
                            return(
                                <ImageComponent data={item.value} key={index} imageType="image" />
                            )
                        }

                        if(item.field.name === "blockImageSlider") {
                            return(
                                <ImageComponent data={item.value} key={index} imageType="slider" />
                            )
                        }

                        if(item.field.name === "blockTextItem") {
                            return(
                               <TextComponent data={item.value} key={index}/>
                            )
                        }

                        if(item.field.name === "blockImageItem") {
                            return(
                                <h1 key={index}>IMAGE</h1>
                            )
                        }

                        if(item.field.type === "boolean" && item.value === true) {
                            return(
                                <ContactForm data={item.value} key={index} />
                            )
                        }

                        return(true);

                    })
                }
            </React.Fragment>
        )



    }


    renderMenu() {
        if(this.state.showMenu) {
            return(
                <MainMenu activeItem={ this.state.slug } data={ this.state.data }/>
            )
        }
    }


    renderPage() {


        if(this.state.isLoaded) {

            let img = API.createImage(this.state.currentPage.headerImage.path );
            let style = { background: "url('" +  img + "')" }

            return (
                <React.Fragment>
                    <Grid item xs={12} md={6} className="pageLeft" style={ style }>
                    </Grid>

                    <Grid item xs={12} md={6} className="pageRight">
                        <div className="content">
                            { this.renderContent() }
                        </div>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    renderPageDesign() {
        if(this.state.windowSize.w < 960 ) {

            if(this.state.isLoaded) {

                let img = API.createImage(this.state.currentPage.headerImage.path);
                let style = "url('" + img + "')";


                return (
                    <>
                        <MobileHead passingClass="mobile-image-block"
                                    data={this.state.data}
                                    showArrow={true}
                                    activeItem={ this.state.slug }
                                    bg={style}/>

                         {this.renderPage()}
                    </>
                )
            }
        }

        return(
            <Grid container className="pageGrid" spacing={0}>
                { this.renderMenu() }
                { this.renderPage() }
            </Grid>
        )

    }


    render() {
        return(
            <>
                <Helmet>
                    <title>{ config.siteTitle + this.state.currentPage.pageTitle }</title>
                    <meta name="description" content={ this.state.currentPage.pageSeoDescription  } />
                </Helmet>
                { this.renderPageDesign() }
            </>
        )
    }



}

export default ContentView;
