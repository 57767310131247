import config from '../config/config';



export default class API {

    static setBaseUrl(url) {
        return(config.cmsUrl + url + config.apiKey);
    }

    static createImage(img) {
        return(config.cmsImageUrl + img);
    }


    static fetchPages = () => new Promise( (resolve, reject) => {

        let url = API.setBaseUrl(config.pagesAPI);
        fetch(url)
            .then( (response) => response.json())
            .then( (response) => {
                resolve({ key: "pages", data: response });
            })
            .catch( (error) => {
                reject({ error: error});
            })

    });



    static fetchHomepage = () => new Promise( (resolve, reject) => {

        let url = API.setBaseUrl(config.homepageAPI);
        fetch(url)
            .then( (response) => response.json())
            .then( (response) => {
                resolve({ key: "homepage", data: response });
            })
            .catch( (error) => {
                reject({ error: error});
            })

    });

    static fetchData = () => new Promise( (resolve, reject) => {

        let appData = {}

        Promise.all([
          //  API.fetchMenuStructure(),
            API.fetchHomepage(),
            API.fetchPages(),
          //  API.fetchBlocks(),
          //  API.fetchNews()
        ])
            .then(result => {
                result.forEach( item => {
                    switch(item.key) {
                        case "homepage": {
                            appData.homepage = item.data;
                            break;
                        }

                        case "pages": {
                            appData.pages = item.data;
                            break;
                        }

                        case "structure": {
                            appData.structure = item.data;
                            break;
                        }

                        case "blocks": {
                            appData.blocks = item.data;
                            break;
                        }

                        case "news": {
                            appData.news = item.data;
                            break;
                        }

                        default: {
                            break;
                        }
                    }
                });

                resolve(appData);
            })
            .catch(err => {
                reject(err)
            })

    });

    static validateEmail(email) {
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static fetchForm = () => new Promise( (resolve, reject) => {

        let url = API.setBaseUrl(config.formsExport);
        fetch(url)
            .then( (response) => response.json())
            .then( (response) => {
                resolve({ data: response });
            })
            .catch( (error) => {
                reject({ error: error});
            })
    });

    static sendForm = (data) => new Promise( (resolve, reject) => {

        let url = API.setBaseUrl(config.formsAPI);

        fetch(url, {
            method: 'post',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({form: data})
        })
        .then(entry => entry.json())
        .then(entry => resolve(entry))
        .catch( err => reject(err));

    });

}
