import React from 'react';
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import Grid from '@material-ui/core/Grid';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import config from "../config/config";
import API from '../lib/API';
import Checkbox from "@material-ui/core/Checkbox";

const sha1 = require('sha1');

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];




class Inschrijving extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            isLoggedIn: false,
            isError: false,
            password: "",
            currentPerson: {},
        }
    }

    fetchData() {

        API.fetchForm()
            .then( result => {
                console.warn(result.data);
                this.setState({
                    isLoaded: true,
                    isLoggedIn: true,
                    isError: false,
                    data: result.data
                })
            })
            .catch(err => {

            });


    }

    login() {
        let passwd = sha1(this.state.password);
        if(passwd === config.fetchKey) {
            this.fetchData();
        } else {
            this.setState({
                isError:true,
                password: ""
            })
        }

    }

    componentDidMount() {

    }

    renderError() {
        if(this.state.isError) {
            return (
                <div>Foute login</div>
            )
        }
    }

    changeItem(item, evt) {

        let target = evt.target;
        let value = target.value;

        this.setState({
            [item]: value
        });

    }

    renderDetails() {
        let mailto = "mailto:" + this.state.currentPerson.email;
        let stage = this.state.currentPerson.checkedTraineeship ? "Als stage" : "";
        let bd = new Date(this.state.currentPerson.birtDate);

        let bdate = bd.getDate() + "-" + bd.getUTCMonth() + "-" + bd.getFullYear();

        if(this.state.currentPerson.full_name) {
            return (
                <div style={{padding: 40}}>
                    <h2>{this.state.currentPerson.full_name}</h2>
                    <strong>{ bdate }</strong>
                    <p>{this.state.currentPerson.address}<br/>
                        {this.state.currentPerson.postcode} {this.state.currentPerson.city}<br/><br/>
                        {this.state.currentPerson.phone}<br/>
                        <a href={mailto}>{this.state.currentPerson.email}</a><br/>
                    </p>
                    <p><strong>Beroep</strong><br/>{this.state.currentPerson.occupation}</p>
                    <p><strong>{ stage }</strong></p>
                    <p><strong>Wil graag</strong><br/>{this.state.currentPerson.wanttodo}</p>
                    { this.renderBeschikbaar() }
                </div>
            )
        }

    }

    renderBeschikbaar() {

        return(
            <table style={{ width: "100%"}}>
                <thead>
                <tr>
                    <th></th>
                    <th>Ma.</th>
                    <th>Di.</th>
                    <th>Wo.</th>
                    <th>Do.</th>
                    <th>Vrij.</th>
                    <th>Za.</th>
                    <th>Zo.</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td><strong>Ochtend</strong></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.monMorning}
                        color="primary"
                        disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.tueMorning}
                        color="primary"
                        disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.wedMorning}
                        color="primary"
                        disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.thurMorning}
                        color="primary"
                        disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.friMorning}
                        color="primary"
                        disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.satMorning}
                        color="primary"
                        disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.sunMorning}
                        color="primary"
                        disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                </tr>

                <tr>
                    <td><strong>Middag</strong></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.monAfternoon}
                       disabled={true}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.tueAfternoon}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.wedAfternoon}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.thurAfternoon}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.friAfternoon}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.satAfternoon}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.sunAfternoon}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                </tr>

                <tr>
                    <td><strong>Avond</strong></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.monEvening}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.tueEvening}
                        color="primary"
                        disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.wedEvening}
                        color="primary"
                        disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.thurEvening}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.friEvening}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.satEvening}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                    <td><Checkbox
                        checked={this.state.currentPerson.sunEvening}
                        color="primary"
                       disabled={true}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                    /></td>
                </tr>



                </tbody>
            </table>
        )



    }

    renderTable() {

        return (
            <TableContainer style={{margin:1}}>

                <Table size="small" aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Naam</TableCell>
                            <TableCell align="left">email</TableCell>
                            <TableCell align="left">telefoon</TableCell>
                            <TableCell align="left">Stad</TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.data.map( (row, index) => {

                            let item = row.data;
                            let mailto = "mailto:" + item.email;
                            let tel = "tel:" + item.phone;
                            return(
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        { item.full_name }
                                    </TableCell>
                                    <TableCell align="left"><a href={mailto}>{ item.email }</a></TableCell>
                                    <TableCell align="left">{ item.phone }</TableCell>
                                    <TableCell align="left">{ item.city }</TableCell>
                                    <TableCell align="center">
                                        <Button onClick={ () => this.setState({ currentPerson: item })}>
                                            Details
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        );



    }

    render() {
        if(!this.state.isLoggedIn) {
            return(
                <div style={{ padding: 60 }}>
                    <InputLabel htmlFor="standard-adornment-amount">Wachtwoord</InputLabel>
                    <Input
                        id="password"
                        type="password"
                        value={this.state.password}
                        onChange={ (evt) => this.changeItem("password", evt)}
                        required
                        color="primary"
                    />

                    <Button variant="contained" color="primary"
                            onClick={ () => this.login() }>
                        Login
                    </Button>
                    { this.renderError() }
                </div>
            )
        }
        return(
            <Grid container spacing={10}>
                <Grid item xs={12} sm={7} >
                    <div style={{padding: 20}}>
                        <h1>Inschrijvingen</h1>
                        { this.renderTable() }
                    </div>
                </Grid>
                <Grid item xs={12} sm={5} >
                    { this.renderDetails() }
                </Grid>
            </Grid>
        );
    }


}

export default Inschrijving;
